import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProjectTile from '../ProjectTile';
import { getWebsitesStart } from '../../actions';
import Socket from '../Socket';
import SearchBar from '../SearchBar';
import { connect }  from 'react-redux';
import {
  displayWebsites,
  subscribeToTimer,
  removeSubscription,
} from '../../utiliity';
import './Projects.css';
import { AllLinks } from '../../constants/websites';

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timestamp: 'reaching to server ....',
    };
  }
  
  componentDidMount() {
    subscribeToTimer((timestamp) => {
      this.setState({
        timestamp,
      });
    });
  }

  componentWillUnmount() {
    removeSubscription();
  }

  componentWillMount() {
    this.props.getWebsitesStart();
  }

  render() {
    console.log('websites: ', AllLinks)
    const {
      timestamp,
    } = this.state;
    return(
      <div className="projectsTop">
        <h1>Completed projects</h1>
        <div className="headTitle">
          <div className="serchBar">
            <SearchBar />
          </div>
        </div>
        <div className="projects">
          {AllLinks.map((item, index) => (
            <ProjectTile
              key={index}
              title={item.title}
              program_type={item.program_type}
              image_link={item.image_link}
              web_link={item.web_link}
              git_link={item.git_link}
            />
          ))}
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    links: displayWebsites(state.websites.links, state.search.filter),
  }
}
export default connect(mapStateToProps, { getWebsitesStart })(ProjectList);
