export const AllLinks = [
  {
    title: 'Marine Upholstery store website',
    program_type: 'React /Redux',
    image_link: 'https://s3.amazonaws.com/photo-links/alpha.png',
    web_link: 'http://alphamarineupholstery.com/',
    git_link: 'https://github.com/mawglih/taran',
  },
  {
    title: 'Hawthone Jeweler',
    program_type: 'React',
    image_link: 'https://s3.amazonaws.com/photo-links/jeweler.png',
    web_link: 'http://hawthornejeweler.com/',
    git_link: 'https://github.com/mawglih/igor-jewelry',
  },
  {
    title: 'Rodo.com',
    program_type: 'NextJS',
    image_link: 'https://s3.amazonaws.com/photo-links/rodo.png',
    web_link: 'http://rodo.com',
  },
  {
    title: 'Life-coach website',
    program_type: 'React / Redux',
    image_link: 'https://s3.amazonaws.com/photo-links/lana.png',
    web_link: 'http://lanalifecoach.com/',
    git_link: 'https://github.com/mawglih/svetlana-psy',
  },
  {
    title: 'Teacher website and store',
    program_type: 'Wordpress / PHP / CSS / HTML',
    image_link: 'https://s3.amazonaws.com/photo-links/vika.png',
    web_link: 'https://vikaraskina.com/',
    git_link: null,
  },
  {
    title: 'Shopping cart',
    program_type: 'React / Redux-thunk',
    image_link: 'https://s3.amazonaws.com/photo-links/web21.png',
    web_link: 'http://shopping-cart-client.s3-website-us-east-1.amazonaws.com/',
    git_link: 'https://github.com/mawglih/shopping-cart.git',
  },
  {
    title: 'Moviedb Search',
    program_type: 'React / Redux',
    image_link: 'https://s3.amazonaws.com/photo-links/web19.png',
    web_link: 'http://moviedb-thunk-project.s3-website-us-east-1.amazonaws.com',
    git_link: 'https://github.com/mawglih/movieDB-thunk.git',
  },
  {
    title: 'Carousel',
    program_type: 'React / Redux-thunk',
    image_link: 'https://s3.amazonaws.com/photo-links/web22.png',
    web_link: 'http://carousel-redux.s3-website-us-east-1.amazonaws.com/',
    git_link: 'https://github.com/mawglih/redux-carousel.git',
  },
  {
    title: 'Iceland Tours Prototype',
    program_type: 'React / Redux',
    image_link: 'https://s3.amazonaws.com/photo-links/web2.png',
    web_link: 'http://iceland-ring-road-redux.s3-website-us-east-1.amazonaws.com',
    git_link: 'https://github.com/mawglih/iceland-ringroad-react',
  },
  {
    title: 'Dashboard prototype',
    program_type: 'Angular 5',
    image_link: 'https://s3.amazonaws.com/photo-links/web6.png',
    web_link: 'http://project-dashboard-angular2.s3-website-us-east-1.amazonaws.com/',
    git_link: 'hhttps://github.com/mawglih/project_dashboard_angular2',
  },

  {
    title: 'Doctor\'s website',
    program_type: 'AngularJS',
    image_link: 'https://s3.amazonaws.com/photo-links/web8.png',
    web_link: 'http://start-fresh-life.org/',
    git_link: 'https://github.com/mawglih/doctor3',
  },
  {
    title: 'Weather search',
    program_type: 'React / Redux / Weather API',
    image_link: 'https://s3.amazonaws.com/photo-links/web15.png',
    web_link: 'http://weather-react.s3-website-us-east-1.amazonaws.com/',
    git_link: 'https://github.com/mawglih/weather-react-redux',
  },
  {
    title: 'BookStore Prototype',
    program_type: 'React / Redux',
    image_link: 'https://s3.amazonaws.com/photo-links/web17.png',
    web_link: 'http://bookstore-react.s3-website-us-east-1.amazonaws.com/',
    git_link: 'https://github.com/mawglih/bookstore-react',
  },
]

export default {};
